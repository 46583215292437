.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 1.5em;

  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    color: #4b4f58;
    font-size: 100%;
    cursor: pointer;
    text-align: center;

    transition: .3s all;

    &:hover {
      color: #0170b9;
      transform: scale(1.1);
    }
  }

  &__linkActive {
    color: #0170b9;
  }
}

.dropdown {
  padding: 20px 1em;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    width: 9px;
    height: 9px;
    margin-left: 9px;
    transform: rotate(180deg);
  }
}

.list {
  display: flex;
  flex-flow: column nowrap;
  width: 150%;
  border-top: 2px solid #0170b9;
  background-color: #ffffff;
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);

  &__item {
    padding: 0.9em 1em;
    color: #4b4f58;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #0170b9;
    }
  }
}
