.root {
  &__input {
    height: auto;
    padding: 0.75em;
    border: 1px solid #dddddd;
    border-radius: 2px;
    background-color: #fafafa;
    font-size: 1rem;
    line-height: 1.6em;
    color: #666;
    transition: all 0.2s linear;

    &:focus {
      border-color: inherit;
      border-style: dotted;
      border-width: thin;
      background-color: #ffffff;
      color: #111;
      outline: none;
    }
  }
}

.validation {
  &__textInvalid {
    font-size: 12px;
    line-height: 16px;
    color: red;
  }

  &__inputInvalid {
    border-color: red;
  }
}
