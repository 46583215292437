@import "@styles/mixins.scss";

.root {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 $offset $offset $offset;
  overflow: hidden;

  &__header {
    flex-basis: 100%;
    flex-grow: 1;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #dddddd;
    border-bottom: none;
    background-color: #3498db;
    color: #ffffff;
    cursor: pointer;
  }

  &__content {
    width: 100%;
    transition: height 0.2s linear, border 0.2s linear, padding 0.2s linear;
  }
}
