@import "@styles/variables.scss";

.root {
  display: flex;
  flex-flow: column nowrap;

  &__title {
    margin-bottom: 8px;
    font-size: 2rem;
    line-height: 1.25em;
    color: map-get($colors, "accent");
  }

  &__lines {
    width: 100%;
    margin-bottom: 8px;
  }
}

.button {
  width: 100%;
  padding: 10px 40px;
  border: none;
  background-color: #0170b9;
  line-height: 1.85;
  color: #ffffff;

  &__available {
    filter: none;
    cursor: pointer;

    &:hover {
      background-color: #3a3a3a;
    }
  }

  &__unavailable {
    filter: grayscale(50%);
  }
}

.error {
  &__text {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: red;
  }
}
