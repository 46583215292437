@import "@styles/mixins.scss";
@import "@styles/variables.scss";

.root {
  margin-top: 20px;

  &__image {
    padding-bottom: 4px;
  }

  &__anchor {
    color: #dddddd;
    padding: 4px 0;
  }

  @include breakpoint("md") {
    margin: 0;
  }
}
