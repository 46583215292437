@import "@styles/mixins.scss";

.root {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  &__backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(170, 170, 170, 0.3);
    z-index: 1001;
  }

  &__content {
    top: 0;
    width: 100%;
    padding: 0.2em;
    z-index: 1002;
  }

  @include breakpoint("md") {
    &__content {
      width: 60%;
    }
  }
}
