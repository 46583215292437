.root {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  background-color: #f9f9f9;

  &__anchor {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #337ebf;
    color: #4f4b58;
    &:hover {
      color: red;
    }
  }
}

.collapse {
  margin: 0;
  border: none;
  border-bottom: 1px solid #337ebf;

  &__header {
    padding: 20px;
    background: #f9f9f9;
    border: none;
    border-bottom: 1px solid #337ebf;
    color: #4f4b58;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    // padding: 0 20px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__arrow {
    width: 16px;
    height: 16px;
    translate: transform 0.4s ease-in-out;
  }

  &__arrowDown {
    width: 16px;
    height: 16px;
    transform: rotate(-180deg);
  }
}

.content {
  &__item {
    padding-left: 40px;
  }
}
