@import "@styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;

  @include breakpoint("lg") {
    flex-direction: row;
    padding: 0 75px;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 35px;

  &__logo {
    padding: 1em 0;
    cursor: pointer;
    transition: .3s all;
    &:hover {
      opacity: .75;
      transform: scale(0.98);
    }
  }

  &__menu {
    width: 26px;
    height: 26px;
    color: #0170b9;
  }

  @include breakpoint("lg") {
    padding: 0 15px;
  }
}
