@import "./reset.scss";
@import "./fonts.scss";
@import "./mixins.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: white) {
  html {
    color-scheme: white;
  }
  body {
    color: white;
    background: white;
  }
}

.notFound {
  width: 200px;
  height: 200px;

  @include breakpoint("md") {
    width: 450px;
    height: 450px;
  }
}
