@import "@styles/variables.scss";

.root {
  justify-content: space-between;

  &__label {
    min-width: 40%;
    padding: 0 $offset;
    color: #4b4f58;
  }

  &__input {
    width: 100%;
  }
}
