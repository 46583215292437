.root {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 2.1em;
  height: 2.1em;
  border-radius: 2px;
  background-color: #0170b9;
  cursor: pointer;
  z-index: 900;

  &__icon {
    fill: #ffffff;
    transform: scale(0.5);
  }
}
