@font-face {
  font-family: "BebasNeueBold";
  src: url("/fonts/BebasNeueBold.ttf");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "BebasNeueBook";
  src: url("/fonts/BebasNeueBook.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
