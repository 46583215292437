@import "@styles/mixins.scss";

.root {
  width: 100%;
  margin-top: auto;
  background-color: #3a3a3a;

  &__main {
    flex-direction: column;
    padding: 20px 40px 40px 40px;

    @include breakpoint("md") {
      flex-direction: row;
      justify-content: space-between;
      padding: 70px 40px;
    }
  }
}
