@import "@styles/mixins.scss";

.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;



  &__header {
    position: sticky;
    top: -1px;
    width: 100%;
    background-color: #ffffff;
    z-index: 400;
    transition: top 0.3s ease-in-out;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: 1em 20px 5.34em;
  }

  &__footer {
    width: 100%;
  }

  @include breakpoint("md") {
    &__main {
      padding: 1em 80px 5.34em 80px;
    }
  }
}
