.root {
  display: flex;
  flex-wrap: nowrap;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}
